













import Vue from 'vue';
import { StepData } from '@/src/components/processes/il-wizard.vue';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';
import oneSchema from './schema/1.projectInformation';
import twoSchema from './schema/2.blueprints';
import threeSchema from './schema/3.systemDetails';
import fourSchema from './schema/4.safetyAnchorPoints';
import fiveSchema from './schema/5.documentation';

export default Vue.extend({
  auth: true,
  data: () => ({
    isLoading: false,
    model: SiHaDocumentationResourceViewModel,
    title: 'page.createOrUpdateDocumentation.title.project',
    steps: [
      {
        breadcrumb: 'page.createOrUpdateDocumentation.label.stepTitles.1',
        component: () => import('./1.projectInformation.vue'),
        schema: oneSchema,
        sendData: true,
      },
      {
        breadcrumb: 'page.createOrUpdateDocumentation.label.stepTitles.2',
        component: () => import('./2.blueprints.vue'),
        schema: twoSchema,
        sendData: true,
      },
      {
        breadcrumb: 'page.createOrUpdateDocumentation.label.stepTitles.3',
        component: () => import('./3.systemDetails.vue'),
        schema: threeSchema,
        sendData: true,
      },
      {
        breadcrumb: 'page.createOrUpdateDocumentation.label.stepTitles.4',
        component: () => import('./4.safetyAnchorPoints.vue'),
        schema: fourSchema,
        disabled: (data: SiHaDocumentationResourceViewModel) => !data.hasSavedSafetyHooks,
        sendData: true,
      },
      {
        breadcrumb: 'page.createOrUpdateDocumentation.label.stepTitles.5',
        component: () => import('./5.documentation.vue'),
        schema: fiveSchema,
        disabled: (data: SiHaDocumentationResourceViewModel) => !data.hasSavedSafetyHooks,
        sendData: true,
      },
    ] as StepData[],
  }),
  computed: {
    siHaDocumentationId() {
      const id = this.$router.currentRoute?.params?.id as string | undefined;
      if (id) {
        return Number.parseInt(id, 10);
      }
      return undefined;
    },
  },
  methods: {
    initializeData() {
      const initializedModel = new SiHaDocumentationResourceViewModel().fromDTO({
      } as Parameters<SiHaDocumentationResourceViewModel['fromDTO']>[0]);
      return initializedModel;
    },
    async loadServiceFunction() {
      this.isLoading = true;
      if (this.siHaDocumentationId) {
        return this.$service.api.siHaDocumentation.getSiHaDocumentation(this.siHaDocumentationId);
      }
      this.isLoading = false;
      return this.initializeData();
    },
    async saveServiceFunction(stepNr: number, model : SiHaDocumentationResourceViewModel) {
      if (typeof this.siHaDocumentationId === 'number') {
        await this.$service.api.siHaDocumentation.updateSiHaDocumentation(this.siHaDocumentationId, model);
      } else {
        // TODO: This should actually return a number directly and not a response-wrapper?
        const response = await this.$service.api.siHaDocumentation.createSiHaDocumentation(model);
        if (!response) {
          throw new Error('Unable to create new Documentation, no id was returned from backend');
        }
        this.$router.replace(`/glaromat/il/siha/process/updateDocumentation/${response}?step=1`);
      }
    },
  },
});
