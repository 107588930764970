import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  ...queryBuilder.properties({
    projectNumber: { type: ['null', 'string'], maxLength: 30 },
    title: { type: 'string', required: true, maxLength: 255 },
    addressLine: { type: 'string', required: true, maxLength: 255 },
    postalCode: { type: 'string', required: true, maxLength: 10 },
    city: { type: 'string', required: true, maxLength: 255 },
    clientCompanyName: { type: 'string', required: true, maxLength: 255 },
    clientFirstName: { type: ['null', 'string'], maxLength: 255 },
    clientLastName: { type: ['null', 'string'], maxLength: 255 },
    clientAddressLine: { type: ['null', 'string'], maxLength: 255 },
    clientPostalCode: { type: ['null', 'string'], maxLength: 10 },
    clientCity: { type: ['null', 'string'], maxLength: 255 },
    clientPhoneNumber: { type: ['null', 'string'], maxLength: 50 },
    clientEMail: { type: ['null', 'string'], pattern: validationPatterns.email, maxLength: 255 },
  }),
};
